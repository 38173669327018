<script setup lang="ts">
import MenuItems from '../utils/MenuItems'
import logo from '../assets/bank_logo.png'
import { RouterView } from 'vue-router'
import Storage from '@/utils/Storage'
import { ref } from 'vue'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import router from '@/router'
import { DynatraceManager } from '@/utils/DynatraceManager'

const op = ref()
const toggle = (event: Event) => {
  op.value.toggle(event)
}
const logout = () => {
  Storage.clearData()
  DynatraceManager.endSession()
  router.push({
    name: 'login'
  })
}
const naviagteToSettings = () => {
  router.push({
    name: 'settingsView'
  })
}
window.addEventListener('popstate', function(event) {
Storage.clearData() 
router.push({
  name: 'login'
})
});


</script>

<template>
  <main class="main-view">
    <Card class="card">
      <template #content>
        <Menubar class="menu-bar" :model="Storage.getEmpAccessRole() == 'Operator_L1' ? MenuItems.MENU_ITEMS_L1 : Storage.getEmpAccessRole() == 'Operator_L2' ? MenuItems.MENU_ITEMS_L2 : Storage.getEmpAccessRole() == 'Operator_L3' || Storage.getEmpAccessRole() == null  ? MenuItems.MENU_ITEMS_L3 : Storage.getEmpAccessRole() == 'Admin' ? MenuItems.MENU_ITEMS_ADMIN : MenuItems.MENU_ITEMS_L3">
          <template #start>
            <a href="https://matrex.in" target="_blank">
              <Avatar :image="logo" />
            </a>
          </template>
          <template #item="{ item, props, hasSubmenu, root }">
            <a class="flex items-center p-0" v-bind="props.action">
              <span :class="item.icon"></span>
              <span class="">{{ item.label }}</span>
              <i
                v-if="hasSubmenu"
                :class="[
                  'fas fa-angle-down',
                  { 'fa-angle-down': root, 'fa-angle-right ml-auto': !root }
                ]"
              ></i>
            </a>
          </template>
          <template #end>
            <div class="profile_container">
              <Chip>Hi, {{ Storage.getUserName() }}</Chip>
              <Button class="profile" icon="fas fa-user" @click="toggle" rounded outlined />
              <Popover ref="op" :dismissable="true" @click="toggle">
                <CustomButton title="Update Profile" icon="" :outlined="true" :disabled="true" />
                <CustomButton title="Change Password" icon="" :outlined="true" :disabled="true" />
                <CustomButton
                  title="Settings"
                  icon="cog"
                  :outlined="true"
                  @click="naviagteToSettings"
                  :disabled="!(Storage.getEmpAccessRole()=='Admin')"
                />
                <Divider />
                <CustomButton title="Logout" icon="sign-out" @click="logout" />
              </Popover>
            </div>
          </template>
        </Menubar>
      </template>
    </Card>
    <div class="content">
      <RouterView class="routerView" />
    </div>
  </main>
</template>

<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.main-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f2f2f2;
  height: 100%;
}

.card {
  width: 100%;
  height: 85px;
}

.menu-bar {
  margin-top: -5px;
}

.profile_container {
  display: flex;
  gap: 10px;
}

.profile {
  --p-button-outlined-primary-hover-background: rgba(50, 95, 186, 0.2);
  --p-button-outlined-primary-border-color: rgb(50, 95, 186);
  --p-button-outlined-primary-active-background: rgba(50, 95, 186, 0.35);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.profile {
  color: rgb(50, 95, 186);
}

.content {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  padding: 20px;
}

.p-popover {
  padding: 16px;
}

.p-popover-content {
  display: flex;
  flex-flow: column;
  gap: 10px;
}

.username {
  font-weight: bold;
  font-size: large;
}
</style>
