<script setup lang="ts">
import SearchComponent from '@/components/SearchComponent.vue'
import { ref } from 'vue'
import type { CustomerDetailsData } from '@/api/models/customer/CustomerDetailsData'
import type { ToastMessageOptions } from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import NotFound from '@/assets/not_found.svg'
import LoadingComponent from '@/components/LoadingComponent.vue'
import ValidationUtility from '@/utils/ValidationUtility'
import ApiService from '@/api/ApiService'
import type { CustomerDetailsRequest } from '@/api/models/customer/CustomerDetailsRequest'
import ConversionUtility from '../utils/ConversionUtility'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import type { CreateFdRequest } from '@/api/models/fd/CreateFdRequest'
import type { CreatedBy } from '@/api/models/CreatedBy'
import Storage from '@/utils/Storage'
import DateUtils from '@/utils/DateUtils'
import router from '@/router'
import type { FD } from '@/api/models/fd/FD'
import type { WithdrawFdRequest } from '@/api/models/fd/WithdrawFdRequest'
import { useConfirm } from 'primevue/useconfirm'
import type { Relationship } from '@/models/Relationship'
import type { FDScheme } from '@/api/models/setting/FDScheme'
import type { FixedDeposit } from '@/api/models/fd/FixedDeposit'

const confirmDialog = useConfirm()
const customerDetails = ref<CustomerDetailsData>()
const nomineeDetails = ref<CustomerDetailsData>()
const isLoading = ref(false)
const toast = useToast()
const createFdVisible = ref<boolean>(false)
const withdrawFdVisible = ref<boolean>(false)
const selectedScheme = ref<FDScheme>()
const fdAmount = ref<number>()
const fdEndDate = ref<number>()
const fdTenure = ref<Date>()

const fdInterestRate = ref<number>()
const fdInterestAmount = ref<number>()
const fdWithdrawToAccount = ref<string>('Customer')
const totalFdAmount = ref<number>()
const penaltyAmount = ref<number>(0)
const nomineeRelation = ref<Relationship>()
const nomineeCustomerId = ref<number>()
const nomineeVisible = ref<boolean>(false)
const activeStep = ref<string>('1')
const withdrawalFd = ref<FD>()
const voucherNo = ref<number>()
const fdStartDate = ref<Date>(new Date())
const minDate = new Date(new Date().setMonth(new Date().getMonth() - 2))
const maxDate = new Date()

const relationships = ref<Relationship[]>([
  { name: 'Husband', code: 'Husband' },
  { name: 'Wife', code: 'Wife' },
  { name: 'Son', code: 'Son' },
  { name: 'Daughter', code: 'Daughter' },
  { name: 'Siblings', code: 'Siblings' },
  { name: 'Cousin', code: 'Cousin' },
  { name: 'Father', code: 'Father' },
  { name: 'Mother', code: 'Mother' },
  { name: 'Uncle', code: 'Uncle' },
  { name: 'Aunt', code: 'Aunt' },
  { name: 'GrandFather', code: 'Grand Father' },
  { name: 'GrandMother', code: 'Grand Mother' },
  { name: 'FatherInLaw', code: 'Father-In-Law' },
  { name: 'MotherInLaw', code: 'Mother-In-Law' },
  { name: 'UncleInLaw', code: 'Uncle-In-Law' },
  { name: 'AuntInLaw', code: 'Aunt-In-Law' },
  { name: 'Guardian', code: 'Guardian' },
  { name: 'Friend', code: 'Friend' },
  { name: 'Other', code: 'Other' }
])

const schemes = ref<FDScheme[]>([])

function onCustomerSearch(query: string) {
  customerDetails.value = undefined
  clearFdData()
  if (!ValidationUtility.validateNumber(query)) {
    showToast('Invalid Customer ID', 'Please provide search details of the customer', 'error')
    return
  }
  if (Number(query.length) < 6) {
    showToast('Invalid Customer ID', 'Customer ID is at least 6 characters long', 'error')
    return
  }
  isLoading.value = true
  const getCustomerRequest: CustomerDetailsRequest = {
    cusId: query
  }
  ApiService.getCustomerDetails(getCustomerRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        customerDetails.value = response.data
      } else {
        showToast('Failed to fetch customers', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch customers', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function fdCalculations() {
  if (!fdAmount.value || !fdEndDate.value) {
    fdAmount.value = undefined
    fdEndDate.value = undefined
    fdTenure.value = undefined
    fdInterestAmount.value = undefined
    showToast('Invalid calculation details', 'Please provide calculation details', 'error')
    return
  }
  if (
    ConversionUtility.toPaiseConversion(fdAmount.value) >
    customerDetails.value?.sbAccount?.balance!!
  ) {
    fdAmount.value = undefined
    fdEndDate.value = undefined
    fdTenure.value = undefined
    fdInterestAmount.value = undefined
    showToast('Invalid FD amount', 'FD amount exceeds the account balance', 'error')
    return
  }
  if (fdAmount.value < 0) {
    showToast('Invalid FD amount', 'FD amount cannot be less than 0', 'error')
    return
  }
  if (
    fdEndDate.value <
      (selectedScheme.value?.startDurationType != 'Days'
        ? selectedScheme.value?.startDuration!! * 365
        : selectedScheme.value?.startDuration!!) ||
    fdEndDate.value >
      (selectedScheme.value?.endDurationType != 'Days'
        ? selectedScheme.value?.endDuration!! * 365
        : selectedScheme.value?.endDuration!!)
  ) {
    fdAmount.value = undefined
    fdEndDate.value = undefined
    fdTenure.value = undefined
    fdInterestAmount.value = undefined
    showToast(
      'Invalid FD Duration',
      'The selected FD duration is not applicable for the current fd',
      'error'
    )
    return
  }
  let paiseFdAmount = ref(ConversionUtility.toPaiseConversion(fdAmount.value))
  fdTenure.value = new Date(DateUtils.getTimestampFromTwoDates(fdStartDate.value, fdEndDate.value))

  fdInterestAmount.value =
    new Date().getFullYear() -
      new Date(customerDetails.value?.customer?.dateOfBirth!!).getFullYear() >=
    60
      ? paiseFdAmount.value *
          Math.pow(
            selectedScheme.value?.scInterestRate!! / 100 + 1,
            DateUtils.getYearsDifference(fdStartDate.value.getTime(), fdTenure.value.getTime())
          ) -
        paiseFdAmount.value
      : paiseFdAmount.value *
          Math.pow(
            selectedScheme.value?.generalInterestRate!! / 100 + 1,
            DateUtils.getYearsDifference(fdStartDate.value.getTime(), fdTenure.value.getTime())
          ) -
        paiseFdAmount.value
  totalFdAmount.value = paiseFdAmount.value + fdInterestAmount.value
}

function handelNomineeSearch(query: string) {
  nomineeDetails.value = undefined
  nomineeCustomerId.value = Number(query)
  if (!ValidationUtility.validateNumber(query)) {
    showToast('Invalid search query', 'Please provide search details of the nominee', 'error')
    return
  }
  if (Number(query.length) < 6) {
    showToast('Invalid search query', 'Customer ID is at least 6 characters long', 'error')
    return
  }
  if (query == customerDetails.value?.customer?.customerId) {
    showToast('Invalid nominee id', 'Customer cannot be nominee for their own FD.', 'error')
    return
  }
  isLoading.value = true
  const getNomineeRequest: CustomerDetailsRequest = {
    cusId: query
  }
  ApiService.getCustomerDetails(getNomineeRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        nomineeDetails.value = response.data
      } else {
        showToast('Failed to fetch nominee', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch nominee', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function handleCreateFd() {
  if (!nomineeRelation.value) {
    showToast(
      'Enter nominee relation',
      'No relation between nominee and customer is provided.',
      'error'
    )
    return
  }
  if (!voucherNo.value) {
    showToast('Invalid voucher number', 'Please enter voucher number', 'error')
    return
  }

  if (voucherNo.value.toString().length > 8) {
    showToast('Invalid voucher number', 'Voucher number should be less than 8 digits!', 'error')
    return
  }
  confirmDialog.require({
    message: `Are you sure you want to Create FD with amount ${ConversionUtility.toStringRupees(fdAmount.value!!)} till ${fdTenure.value?.toDateString().slice(3)} (${fdEndDate.value}-Days)?`,
    header: 'Confirmation to create FD',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, Create FD'
    },
    accept() {
      if (!fdAmount.value || !fdEndDate.value || !fdTenure.value || !fdInterestAmount.value) {
        fdAmount.value = undefined
        fdEndDate.value = undefined
        fdTenure.value = undefined
        fdInterestAmount.value = undefined
        showToast('Invalid calculation details', 'Please provide calculation details', 'error')
        return
      }

      isLoading.value = true
      const createBy: CreatedBy = {
        empId: Storage.getEmpId()!!,
        createdAt: new Date().getTime()
      }
      const fd: FixedDeposit = {
        customerId: Number(customerDetails.value?.customer?.customerId),
        principleAmount: ConversionUtility.toPaiseConversion(fdAmount.value),
        rateOfInterest: fdInterestRate.value!!,
        totalInterestEarned: Number(fdInterestAmount.value.toFixed(0)),
        maturityAt: new Date(fdTenure.value).getTime(),
        nomineeCustomerId: nomineeCustomerId.value!!,
        nomineeRelationship: nomineeRelation.value?.name!!,
        createdAt: fdStartDate.value.getTime(),
        createdBy: createBy
      }
      const createFdRequest: CreateFdRequest = {
        fixedDeposit: fd,
        voucherNo: voucherNo.value!!
      }
      ApiService.createFd(createFdRequest)
        .then((response) => {
          setTimeout(() => {
            if (response.statusCode == 401) {
              Storage.clearData()
              router.push({
                name: 'login'
              })
            }
          }, 2000)
          if (response.statusCode == 200) {
            showToast('FD Created successfully!', '', 'success')
            createFdVisible.value = false
            onCustomerSearch(customerDetails.value?.customer?.customerId!!)
          } else {
            showToast('Failed to create FD', '', 'error')
          }
        })
        .catch((error) => {
          showToast('Failed to create FD', error, 'error')
        })
        .finally(() => {
          isLoading.value = false
          clearFdData()
        })
    },
    reject() {}
  })
}

function handelWithdrawFd() {
  if (!voucherNo.value) {
    showToast('Invalid voucher number', 'Please enter voucher number', 'error')
    return
  }

  if (voucherNo.value.toString().length > 8) {
    showToast('Invalid voucher number', 'Voucher number should be less than 8 digits!', 'error')
    return
  }

  confirmDialog.require({
    message: `Are you sure you want to Withdraw FD with amount ${ConversionUtility.toStringRupeesConversion(withdrawalFd.value?.totalInterestEarned!! + withdrawalFd.value?.principleAmount!! - ConversionUtility.toPaiseConversion(penaltyAmount.value))} ${fdWithdrawToAccount.value == 'Customer' ? 'to customers SB account.' : 'to nominees SB account.'}`,
    header: 'Confirmation to withdraw FD',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, withdraw FD'
    },
    accept() {
      isLoading.value = true
      const createBy: CreatedBy = {
        empId: Storage.getEmpId()!!,
        createdAt: Date.now()
      }
      const withdrawFdRequest: WithdrawFdRequest = {
        fdId: withdrawalFd.value?.fdId!!,
        sbAccountId: customerDetails.value?.sbAccount?.accountId!!,
        totalInterestEarned: withdrawalFd.value?.totalInterestEarned!!,
        penaltyFees: ConversionUtility.toPaiseConversion(penaltyAmount.value),
        voucherNo: voucherNo.value!!,
        requestAt: new Date().getTime(),
        isTransferToNominee: fdWithdrawToAccount.value != 'Customer',
        createdBy: createBy
      }

      ApiService.withdrawFd(withdrawFdRequest)
        .then((response) => {
          setTimeout(() => {
            if (response.statusCode == 401) {
              Storage.clearData()
              router.push({
                name: 'login'
              })
            }
          }, 2000)
          if (response.statusCode == 200) {
            showToast(
              'FD withdrawn successfully!',
              fdWithdrawToAccount.value == 'Customer'
                ? 'Fd amount will be added to your savings account.'
                : 'Fd amount will be added to nominees savings account.',
              'success'
            )
            withdrawFdVisible.value = false
            onCustomerSearch(customerDetails.value?.customer?.customerId!!)
          } else {
            showToast('Failed to create FD', '', 'error')
            withdrawFdVisible.value = false
          }
        })
        .catch((error) => {
          showToast('Failed to create FD', error, 'error')
          withdrawFdVisible.value = false
        })
        .finally(() => {
          isLoading.value = false
          withdrawalFd.value = undefined
        })
    },
    reject() {}
  })
}

function handelFetchFdSchemes() {
  if (!customerDetails.value?.sbAccount?.balance) {
    showToast(
      'You do not have sufficient balance to open FD',
      'Add some balance to create FD',
      'error'
    )
    return
  }
  isLoading.value = true
  ApiService.getFdScheme()
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        schemes.value = response.data
        createFdVisible.value = true
      } else if (response.statusCode == 404) {
        showToast('There are no FD Schemes', 'Pls set Fd Schemes from settings.', 'error')
      } else {
        showToast('Failed to fetch FD Schemes', '', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch FD Schemes', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function handelFetchNomineeDetails(query: string) {
  isLoading.value = true
  nomineeDetails.value = undefined
  const request: CustomerDetailsRequest = {
    cusId: query
  }
  ApiService.getCustomerDetails(request)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        nomineeDetails.value = response.data
        nomineeVisible.value = true
      } else {
        showToast('Failed to fetch', '', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function clearFdData() {
  selectedScheme.value = undefined
  fdAmount.value = undefined
  fdEndDate.value = undefined
  fdTenure.value = undefined
  fdInterestAmount.value = undefined
  fdInterestRate.value = undefined
  activeStep.value = '1'
  nomineeDetails.value = undefined
  nomineeRelation.value = undefined
  nomineeCustomerId.value = undefined
  voucherNo.value = undefined
  fdStartDate.value = new Date()
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>

<template>
  <main class="main">
    <Toast class="error-p-toast" />
    <Card class="search-card">
      <template #content>
        <ConfirmDialog :draggable="false" :closable="false" />
        <SearchComponent
          title="Search FD"
          placeholder="Search with Customer ID."
          subtitle="Search and Create FD's for a Linked SB Account."
          @search-click="onCustomerSearch"
          @enter-press="onCustomerSearch"
        />
        <div v-if="!customerDetails && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip
            label="No customers found for the provided search query!"
            style="font-weight: 600"
          />
        </div>
        <div v-if="isLoading">
          <LoadingComponent
            text="Please wait while we process the request!"
            :dialogVisiblity="isLoading"
          />
        </div>
        <div class="cont" v-if="customerDetails?.customer && !isLoading">
          <div class="holder-details">
            <div>
              <span class="text-heading">Customer ID: </span>
              <span class="text">{{ customerDetails?.customer?.customerId }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Customer Name: </span>
              <span class="text"
                >{{ customerDetails?.customer?.firstName }}
                {{ customerDetails?.customer?.middleName ?? '' }}
                {{ customerDetails?.customer?.lastName }}<br
              /></span>
            </div>
            <div>
              <span class="text-heading">Balance: </span>
              <span class="text"
                >{{
                  ConversionUtility.toStringRupeesConversion(customerDetails?.sbAccount?.balance!!)
                }}<br
              /></span>
            </div>
            <div>
              <span class="text-heading">Created On: </span>
              <span class="text"
                >{{ ConversionUtility.toDateFormat(customerDetails?.customer?.createdAt!!) }}<br
              /></span>
            </div>
          </div>
          <Divider />
          <h2 class="text-3xl font-bold m-0">Linked FD's</h2>
          <DataTable v-if="customerDetails?.fdAccount!![0]" :value="customerDetails.fdAccount">
            <Column field="fdId" header="ID" />
            <Column field="principleAmount" header="Principal Amount">
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.principleAmount) }}
              </template>
            </Column>
            <Column field="maturityAt" header="Maturity">
              <template #body="slotProps">
                {{ ConversionUtility.toDateFormat(slotProps.data.maturityAt) }}
              </template>
            </Column>
            <Column field="totalInterest" header="Total Interest">
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.totalInterestEarned) }}
              </template>
            </Column>
            <Column header="Cumulative Balance">
              <template #body="slotProps">
                {{
                  ConversionUtility.toStringRupeesConversion(
                    slotProps.data.totalInterestEarned + slotProps.data.principleAmount
                  )
                }}
              </template>
            </Column>
            <Column header="Nominee Id" field="nomineeCustomerId"></Column>
            <Column header="Nominee Details" style="display: flex; justify-content: center">
              <template #body="slotProps">
                <CustomButton
                  title="View"
                  icon="eye"
                  @click="handelFetchNomineeDetails(slotProps.data.nomineeCustomerId)"
                ></CustomButton>
                <Drawer v-model:visible="nomineeVisible" position="bottom" style="height: auto">
                  <Fieldset legend="Nominee Details">
                    <div class="nomineeSection">
                      <div class="nomineeDiv">
                        <span class="text-heading">Nominee Name: </span>
                        <span class="text"
                          >{{ nomineeDetails?.customer?.firstName }}
                          {{ nomineeDetails?.customer?.middleName ?? '' }}
                          {{ nomineeDetails?.customer?.lastName }}<br
                        /></span>
                      </div>
                      <div class="nomineeDiv">
                        <span class="text-heading">Nominee Relation: </span>
                        <span class="text">{{ slotProps.data.nomineeRelationship }}</span>
                      </div>
                      <div class="nomineeDiv">
                        <span class="text-heading">Phone number: </span>
                        <span class="text">{{ nomineeDetails?.customer?.phoneNumber }}</span>
                      </div>
                      <div class="nomineeDiv">
                        <span class="text-heading">Customer ID: </span>
                        <span class="text">{{ nomineeDetails?.customer?.customerId }}</span>
                      </div>
                      <div class="nomineeDiv">
                        <span class="text-heading">Email: </span>
                        <span class="text">{{ nomineeDetails?.customer?.email ?? '--' }}</span>
                      </div>
                      <div class="nomineeDiv">
                        <span class="text-heading">DOB: </span>
                        <span class="text">{{
                          ConversionUtility.toDateFormat(nomineeDetails?.customer?.dateOfBirth!!)
                        }}</span>
                      </div>
                      <div class="nomineeDiv">
                        <span class="text-heading">Aadhar Number: </span>
                        <span class="text">{{ nomineeDetails?.customer?.aadharNumber }}</span>
                      </div>
                      <div class="nomineeDiv">
                        <span class="text-heading">PAN: </span>
                        <span class="text">{{ nomineeDetails?.customer?.panNumber }}</span>
                      </div>
                      <div class="nomineeDiv">
                        <span class="text-heading">Created on: </span>
                        <span class="text">{{
                          ConversionUtility.toDateFormat(nomineeDetails?.customer?.createdAt!!)
                        }}</span>
                      </div>
                      <div class="nomineeDiv">
                        <span class="text-heading">Address: </span>
                        <span class="text"
                          >{{ nomineeDetails?.customer?.houseNumber
                          }}{{ nomineeDetails?.customer?.addressLine1
                          }}{{ nomineeDetails?.customer?.addressLine2 ?? '' }}</span
                        >
                      </div>
                      <div class="nomineeDiv">
                        <span class="text-heading">Taluk: </span>
                        <span class="text">{{ nomineeDetails?.customer?.taluk }}</span>
                      </div>
                      <div class="nomineeDiv">
                        <span class="text-heading">City: </span>
                        <span class="text">{{ nomineeDetails?.customer?.city }}</span>
                      </div>
                      <div class="nomineeDiv">
                        <span class="text-heading">District: </span>
                        <span class="text">{{ nomineeDetails?.customer?.district }}</span>
                      </div>
                    </div>
                  </Fieldset>
                </Drawer>
              </template>
            </Column>
            <Column header="" style="width: 5rem">
              <template #body="slotProps">
                <CustomButton
                  title="Withdraw"
                  icon="money-bill"
                  :outlined="true"
                  :rounded="true"
                  severity="secondary"
                  @click="
                    () => {
                      withdrawalFd = slotProps.data
                      withdrawFdVisible = true
                      penaltyAmount = 0
                      fdWithdrawToAccount = 'Customer'
                      voucherNo = undefined
                    }
                  "
                />
              </template>
            </Column>
          </DataTable>
          <div v-else>
            <Tag class="mb-2" severity="secondary">No FD's linked to this customer.</Tag><br>
            <Tag v-if="customerDetails?.sbAccount?.isClosed" severity="danger">SB Account has closed for this customer.Can't create FD A/C.</Tag>
          </div>
          <div class="end-button-container">
            <CustomButton
              title="Create new FD"
              icon="add"
              @click="handelFetchFdSchemes(), clearFdData()"
              :disabled="customerDetails.sbAccount?.isClosed"
            />
          </div>
        </div>
        <Dialog
          :visible="withdrawFdVisible"
          :header="`Withdraw ${new Date().getTime() < withdrawalFd?.maturityAt!! ? 'Premature' : ''} FD no.-${withdrawalFd?.fdId}`"
          modal
          :draggable="false"
          :closable="false"
          style="width: 55vw"
        >
          <div class="cont">
            <div class="holder-details" style="flex-direction: column">
              <div style="padding-bottom: 1rem">
                <span class="text-heading">Name: </span>
                <span class="text"
                  >{{ customerDetails?.customer?.firstName }}
                  {{ customerDetails?.customer?.middleName ?? '' }}
                  {{ customerDetails?.customer?.lastName }}</span
                >
              </div>
              <Panel header="Details" :toggleable="false">
                <div class="fd-withdrawal-div">
                  <span class="text-heading">Withdraw FD to</span>
                  <span class="radio-button">
                    <span class="radio-button-options">
                      <RadioButton
                        v-model="fdWithdrawToAccount"
                        inputId="account1"
                        name="customer"
                        value="Customer"
                      />
                      <label for="account1" class="text-heading">Customer</label>
                    </span>
                    <span class="radio-button-options">
                      <RadioButton
                        v-model="fdWithdrawToAccount"
                        inputId="account2"
                        name="customer"
                        value="Nominee"
                      />
                      <label for="account2" class="text-heading">Nominee</label>
                    </span>
                  </span>
                </div>
                <div class="fd-withdrawal-div">
                  <span class="text-heading">Principal Amount </span>
                  <span class="text"
                    >{{ ConversionUtility.toStringRupeesConversion(withdrawalFd?.principleAmount!!)
                    }}<br
                  /></span>
                </div>
                <div class="fd-withdrawal-div">
                  <span class="text-heading"
                    >Total Interest for
                    {{
                      DateUtils.dateDifference(
                        withdrawalFd?.createdAt!!,
                        withdrawalFd?.maturityAt!!
                      )
                    }}@{{ withdrawalFd?.rateOfInterest }}%</span
                  >
                  <span class="text"
                    >{{
                      ConversionUtility.toStringRupeesConversion(
                        withdrawalFd?.totalInterestEarned!!
                      )
                    }}<br
                  /></span>
                </div>
                <div style="padding-top: 1.5rem" class="fd-withdrawal-div">
                  <span class="text-heading"></span>
                  <span>
                    <FloatLabel v-if="new Date().getTime() < withdrawalFd?.maturityAt!!">
                      <InputNumber
                        v-model="penaltyAmount"
                        locale="en-IN"
                        prefix="- ₹ "
                        id="penaltyAmount"
                        :min="0"
                        @beforeinput="
                          () => {
                            if (
                              penaltyAmount >
                              ConversionUtility.toRuppesCoversion(
                                withdrawalFd?.totalInterestEarned!! +
                                  withdrawalFd?.principleAmount!!
                              )
                            ) {
                              showToast(
                                'Penalty Amount is greater than total FD amount',
                                '',
                                'error'
                              )
                            }
                          }
                        "
                      />
                      <label for="penaltyAmount">Enter penalty amount </label>
                    </FloatLabel>
                    <br />
                    <InputNumber
                      :useGrouping="false"
                      v-model="voucherNo"
                      placeholder="Voucher Number"
                      :min="1"
                    />
                  </span>
                </div>
                <div
                  class="fd-withdrawal-div"
                  v-if="new Date().getTime() < withdrawalFd?.maturityAt!!"
                >
                  <span class="note"
                    ><span class="mandatory" style="color: red">*</span>After entering penalty
                    amount press enter.</span
                  >
                </div>
                <Divider />
                <div class="fd-withdrawal-div">
                  <span class="text-heading">Total: </span>
                  <span class="text"
                    >{{
                      penaltyAmount <
                      ConversionUtility.toRuppesCoversion(
                        withdrawalFd?.totalInterestEarned!! + withdrawalFd?.principleAmount!!
                      )
                        ? ConversionUtility.toStringRupeesConversion(
                            withdrawalFd?.totalInterestEarned!! +
                              withdrawalFd?.principleAmount!! -
                              ConversionUtility.toPaiseConversion(penaltyAmount ?? 0)
                          )
                        : ConversionUtility.toStringRupeesConversion(
                            withdrawalFd?.totalInterestEarned!! + withdrawalFd?.principleAmount!!
                          )
                    }}<br
                  /></span>
                </div>
              </Panel>
            </div>
            <div class="operations">
              <p></p>
              <CustomButton
                title="Withdraw"
                icon="money-bill"
                @click="handelWithdrawFd()"
                :disabled="
                  !(
                    penaltyAmount <
                    ConversionUtility.toRuppesCoversion(
                      withdrawalFd?.totalInterestEarned!! + withdrawalFd?.principleAmount!!
                    )
                  )
                "
              />
            </div>
          </div>
          <div class="end-button-container">
            <CustomButton
              title="close"
              icon="times"
              @click="(withdrawFdVisible = false), (withdrawalFd = undefined)"
            />
          </div>
        </Dialog>
        <Dialog
          header="Create new FD"
          :visible="createFdVisible"
          modal
          :closable="false"
          :draggable="false"
        >
          <Stepper :value="activeStep" linear>
            <StepList value="">
              <Step value="1">Select a Scheme</Step>
              <Step value="2">FD Details</Step>
              <Step value="3">Nominee Details</Step>
            </StepList>
            <StepPanels value="">
              <StepPanel value="1">
                <div class="dialog-container">
                  <Fieldset class="schemes" legend="Schemes">
                    <div class="next-tag center-tag">
                      <Tag severity="secondary"
                        >Once you select a scheme you will be redirected to the next step.
                      </Tag>
                    </div>
                    <div>
                      <Panel header="Select a Scheme">
                        <DataTable
                          v-model:selection="selectedScheme"
                          @row-select="
                            (activeStep = '2'),
                              (fdInterestRate =
                                new Date().getFullYear() -
                                  new Date(
                                    customerDetails?.customer?.dateOfBirth!!
                                  ).getFullYear() >=
                                60
                                  ? selectedScheme?.scInterestRate
                                    ? selectedScheme?.scInterestRate
                                    : undefined
                                  : selectedScheme?.generalInterestRate
                                    ? selectedScheme?.generalInterestRate
                                    : undefined)
                          "
                          :value="schemes"
                          dataKey="id"
                          tableStyle="min-width: 50rem"
                        >
                          <Column selectionMode="single"></Column>
                          <Column
                            header="Start Duration"
                            field="startDuration"
                            style="text-align: start; width: 20%"
                          >
                            <template #body="slotProps">
                              {{ slotProps.data.startDuration }}-{{
                                slotProps.data.startDuration == 1
                                  ? slotProps.data.startDurationType.toString().slice(0, -1)
                                  : slotProps.data.startDurationType
                              }}
                            </template>
                          </Column>
                          <Column
                            header="End Duration"
                            field="endDuration"
                            style="text-align: start; width: 20%"
                          >
                            <template #body="slotProps">
                              {{ slotProps.data.endDuration }}-{{
                                slotProps.data.endDuration == 1
                                  ? slotProps.data.endDurationType.toString().slice(0, -1)
                                  : slotProps.data.endDurationType
                              }}
                            </template>
                          </Column>
                          <Column
                            header="General Interest Rates"
                            field="generalInterestRates"
                            style="text-align: center; width: 23%"
                          >
                            <template #body="slotProps">
                              {{ slotProps.data.generalInterestRate ?? `null}` }}
                              %
                            </template>
                          </Column>
                          <Column
                            header="Senior Citizen Interest Rates"
                            field="scInterestRate"
                            style="text-align: center; width: 27%"
                          >
                            <template #body="slotProps">
                              {{ slotProps.data.scInterestRate ?? `null}` }}
                              %
                            </template>
                          </Column>
                        </DataTable>
                      </Panel>
                    </div>
                  </Fieldset>
                </div>
              </StepPanel>
              <StepPanel value="2">
                <div class="dialog-container">
                  <Fieldset legend="Selected Scheme">
                    <div class="holder-details">
                      <div>
                        <span class="text-heading">Duration: </span>
                        <span class="text"
                          >{{ selectedScheme?.startDuration }}-{{
                            selectedScheme?.startDuration == 1
                              ? selectedScheme?.startDurationType.toString().slice(0, -1)
                              : selectedScheme?.startDurationType
                          }}
                          to
                          {{ selectedScheme?.endDuration }}-{{
                            selectedScheme?.endDuration == 1
                              ? selectedScheme?.endDurationType.toString().slice(0, -1)
                              : selectedScheme?.endDurationType
                          }}</span
                        >
                      </div>
                      <div>
                        <span class="text-heading">Applicable Interest Rate: </span>
                        <span class="text">{{ fdInterestRate }}%</span>
                      </div>
                      <div>
                        <span class="text-heading">Balance: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupeesConversion(
                            customerDetails?.sbAccount?.balance!!
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="center-tag">
                      <Tag severity="secondary"
                        >*Principal amount should be less than customers balance.
                      </Tag>
                    </div>
                  </Fieldset>
                  <Fieldset legend="FD Calculator">
                    <div class="calculator">
                      <div class="calculator-form">
                        <FloatLabel>
                          <InputNumber
                            v-model="fdAmount"
                            input-id="intgeronly"
                            :min="0"
                            locale="en-IN"
                            prefix="₹ "
                            id="amount"
                            class="input-text"
                          />
                          <label for="amount">Enter FD amount</label>
                        </FloatLabel>
                        <FloatLabel>
                          <InputNumber
                            v-model="fdEndDate"
                            input-id="intgeronly"
                            :min="0"
                            id="amount"
                            class="input-text"
                          />
                          <label for="date">Enter FD duration(in days)</label>
                        </FloatLabel>
                        <FloatLabel>
                          <label for="selectedDate" class="mandatory">From</label>
                          <DatePicker
                            v-model:model-value="fdStartDate"
                            id="selectedDate"
                            class="input-text"
                            :min-date="minDate"
                            :maxDate="maxDate"
                            dateFormat="dd/mm/yy"
                          />
                        </FloatLabel>
                        <CustomButton title="Calculate" icon="calculator" @click="fdCalculations" />
                      </div>
                    </div>
                    <p class="note">
                      Note: valid duration =
                      <span style="font-weight: 700; font-size: 14px">
                        {{
                          selectedScheme?.startDurationType != 'Days'
                            ? selectedScheme?.startDuration!! * 365
                            : selectedScheme?.startDuration
                        }}
                        to
                        {{
                          selectedScheme?.endDurationType != 'Days'
                            ? selectedScheme?.endDuration!! * 365
                            : selectedScheme?.endDuration
                        }}
                        Days
                      </span>
                    </p>
                  </Fieldset>
                  <Fieldset legend="FD Details">
                    <div class="holder-details">
                      <div>
                        <span class="text-heading">Interest Rate: </span>
                        <span class="text">{{ fdInterestRate }}%</span>
                      </div>
                      <div>
                        <span class="text-heading">FD matures on: </span>
                        <span class="text">{{ 
                        fdTenure ? ConversionUtility.toDateFormat(Number(fdTenure)) : '---' 
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Interest Amount: </span>
                        <span class="text">{{
                          fdInterestAmount
                            ? ConversionUtility.toStringRupees(
                                ConversionUtility.toRuppesCoversion(fdInterestAmount!!)
                              )
                            : ' --- '
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Principal Amount: </span>
                        <span class="text">{{
                          fdAmount ? ConversionUtility.toStringRupees(fdAmount!!) : ' --- '
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Total Amount: </span>
                        <span class="text">{{
                          fdInterestAmount && fdAmount
                            ? ConversionUtility.toStringRupees(
                                ConversionUtility.toRuppesCoversion(fdInterestAmount!!) + fdAmount!!
                              )
                            : ' --- '
                        }}</span>
                      </div>
                    </div>
                    <div class="operations">
                      <p></p>
                      <CustomButton
                        title="Next"
                        icon="add"
                        :outlined="true"
                        :disabled="!fdTenure"
                        @click="activeStep = '3'"
                      />
                    </div>
                    <p class="note">
                      Note: The Interest rate is applied based on the age of Customer.
                    </p>
                  </Fieldset>
                </div>
              </StepPanel>
              <StepPanel value="3">
                <div class="dialog-container">
                  <span class="note">Note: Nominee has to be existing customer in the bank.</span>
                  <div class="cont" style="padding-top: 10px">
                    <SearchComponent
                      title="Search Nominee"
                      placeholder="Search with Customer ID."
                      subtitle="Search for a nominee using Customer ID."
                      @search-click="handelNomineeSearch"
                      @enter-press="handelNomineeSearch"
                    />
                    <Fieldset legend="Nominee Details" v-if="nomineeDetails">
                      <div class="holder-details">
                        <div>
                          <span class="text-heading">Nominee name: </span>
                          <span class="text"
                            >{{ nomineeDetails.customer?.firstName }}
                            {{ nomineeDetails.customer?.middleName ?? '' }}
                            {{ nomineeDetails.customer?.lastName }}</span
                          >
                        </div>
                        <div>
                          <span class="text-heading">Phone number: </span>
                          <span class="text">{{ nomineeDetails.customer?.phoneNumber }}</span>
                        </div>
                        <div>
                          <span class="text-heading">Created on: </span>
                          <span class="text">{{
                            ConversionUtility.toDateFormat(nomineeDetails.customer?.createdAt!!)
                          }}</span>
                        </div>
                      </div>
                      <div class="operations">
                        <Select
                          class="relationship-select"
                          placeholder="Select relation with nominee"
                          v-model="nomineeRelation"
                          inputId="relationships"
                          :options="relationships"
                          filter
                          optionLabel="name"
                          overlayClass="gender-overlay"
                        />
                        <InputNumber
                          :useGrouping="false"
                          v-model="voucherNo"
                          :min="1"
                          placeholder="Voucher Number"
                        />
                        <CustomButton
                          title="Create FD"
                          icon="add"
                          :outlined="true"
                          :disabled="!nomineeRelation && !nomineeCustomerId"
                          @click="handleCreateFd()"
                        />
                      </div>
                    </Fieldset>
                  </div>
                </div>
              </StepPanel>
            </StepPanels>
          </Stepper>
          <div class="end-button-container">
            <CustomButton
              title="close"
              icon="times"
              @click="(createFdVisible = false), clearFdData()"
            />
          </div>
        </Dialog>
      </template>
    </Card>
  </main>
</template>

<style scoped>
h2 {
  text-align: left;
  margin: 1rem 2rem;
}

.nomineeDiv {
  display: flex;
  flex-direction: column;

  .header {
    font-size: small;
    margin-bottom: 5px;
  }

  .field {
    font-weight: 600;
  }
}

.nomineeSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 15px;
  row-gap: 20px;
  padding: 1rem;
}

.fd-withdrawal-div {
  display: flex;
  justify-content: space-between;
}

.end-button-container {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: end;
}

.dialog-container {
  width: 70vw;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.schemes {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
  flex-direction: column;
  gap: 1rem;

  .next-tag {
    margin: 0 0 1rem 0;
  }
}

.center-tag {
  display: flex;
  justify-content: center;
}

.calculator {
  .calculator-form {
    display: flex;
    width: 100%;
    gap: 2rem;
    padding-top: 1.5rem;

    .input-text {
      width: 18vw;
    }
  }
}

.radio-button {
  display: flex;
  gap: 1rem;
  padding-bottom: 0.5rem;

  .radio-button-options {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}

.relationship-select {
  width: 30vw;
  height: 45px;
  font-family: Epilogue, serif;
}
</style>
